import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { format } from 'date-fns'
import { ClassificationSchemeRead } from "@/client/models"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function safeFormatDate(dateString: string | Date | null | undefined, fallback = 'N/A') {
  try {
    if (!dateString) return fallback
    const date = typeof dateString === 'string' ? new Date(dateString) : dateString
    return isNaN(date.getTime()) ? fallback : format(date, 'PP p')
  } catch {
    return fallback
  }
}

export function formatClassificationValue(
  value: Record<string, unknown> | null | undefined, 
  scheme?: ClassificationSchemeRead
): string {
  if (!value || !scheme) return 'N/A';

  const schemeValue = value[scheme.name] || value?.value || value;

  switch (scheme.type) {
    case 'int':
      if (scheme.scale_min === 0 && scheme.scale_max === 1) {
        return (Number(schemeValue) || 0) > 0.5 ? 'Positive' : 'Negative';
      }
      return typeof schemeValue === 'number' ? schemeValue.toFixed(2) : String(schemeValue);
      
    case 'str':
      if (scheme.is_set_of_labels && scheme.labels) {
        if (Array.isArray(schemeValue)) {
          return schemeValue.join(', ');
        }
        return scheme.labels[Number(schemeValue as number)] || String(schemeValue);
      }
      return String(schemeValue);
      
    case 'List[Dict[str, any]]':
      return JSON.stringify(schemeValue, null, 2);
      
    case 'List[str]':
      if (scheme.is_set_of_labels && scheme.labels) {
        return Array.isArray(schemeValue) ? 
          (schemeValue as []).map(v => scheme.labels && scheme.labels[v as number]).join(', ') :
          scheme.labels && scheme.labels[schemeValue as number];
      }
      return String(schemeValue);
      
    default:
      return JSON.stringify(schemeValue, null, 2);
  }
}
